import { Controller } from "@hotwired/stimulus"
import TomSelect from "tom-select"

export default class extends Controller {
  connect() {
    this.select = new TomSelect(this.element, {
      create: function(input, callback){
        fetch("/suppliers", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "X-CSRF-Token": document.querySelector("meta[name='csrf-token']").content
          },
          body: JSON.stringify({
            "supplier": {"name": input, "margin": ""}
          })
        }).then(response => response.json())
          .then(data => {
            callback({value: data["id"], text: data["name"]});
          });

      }
    })
  }

  disconnect() {
    this.select.destroy()
  }
}
