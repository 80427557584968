import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    this.element.addEventListener("change", this.handleSelectChange.bind(this))
  }

  handleSelectChange(event) {
    const selectedValue = event.target.value
    if (selectedValue) {
      window.location = `${selectedValue}`
    }
  }

}