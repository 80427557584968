import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"

export default class extends Controller {

  static classes = ["selected"]

  toggleCheckboxAndRow(event) {
    const row = this._findRow(event.target);
    const checkbox = row.querySelector('input[type="checkbox"]');
    checkbox.checked = !checkbox.checked
    this._toggleRowSelection(row, checkbox.checked)
  }

  toggleRow(event) {
    const row = this._findRow(event.target);
    this._toggleRowSelection(row, event.target.checked)
  }

  edit(event) {
    this.show(); // close editor if it is open

    const itemDiv = this._findRow(event.target);
    const path = "item_" + event.target.id;
    get(itemDiv.dataset["itemEdit"] + "?focus=" + path, {responseKind: "turbo-stream"}).then(r =>{
      this.dispatch("edit");
    });
  }

  show(event) {
    // if event is not null but code is null it was Autofill and we don't want to close editor
    if (event != null && event.code == null) return;

    const form = document.querySelector("form[data-item-form]");
    if (form === null) return;
    const itemDiv = this._findRow(form);
    get(itemDiv.dataset["itemShow"], {responseKind: "turbo-stream"}).then(r => {
      this.dispatch("show");
    });
  }

  _findRow(element) {
    while (element) {
      if (element.dataset && element.dataset["itemShow"]) {
        return element;
      }
      element = element.parentElement;
    }
    return null;
  }

  _toggleRowSelection(row, isChecked) {
    row.classList.toggle(this.selectedClass, isChecked)
  }


}
