import { Controller } from "@hotwired/stimulus"
import {get} from "@rails/request.js";

export default class extends Controller {

  static values = { focus: String, rosePassionSearchPath: String };
  static targets = ["purchaseSku", "sku"];

  connect() {
    let formField = document.getElementById(this.focusValue);
    if (!formField) formField = document.getElementById("item_purchase_sku");
    if (formField) formField.focus();
  }

  search(event) {
    event.preventDefault();  // This prevents the default form submission

    console.log("### 111 " + this.purchaseSkuTarget + ", " + this.purchaseSkuTarget.value);

    get(this.rosePassionSearchPathValue + "?purchase_sku=" + this.purchaseSkuTarget.value, {responseKind: "turbo-stream"});
  }

}
