import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = [
    "searchInput",
    "list"
  ]

  performSearch() {
    const searchTerm = this.searchInputTarget.value.toLowerCase();
    const listItems = Array.from(this.listTarget.querySelectorAll("[data-searchable]"));

    listItems.forEach((item) => {
      const text = item.getAttribute("data-searchable").toLowerCase();
      if (text.includes(searchTerm)) {
        item.style.display = "";
      } else {
        item.style.display = "none";
      }
    });
  }

}