import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = [
    "headerCheckbox",
    "rowCheckbox",
    "row",
    "toolbarNone",
    "toolbarSome",
    "toolbarOne",
  ]

  static classes = [
    "selected"
  ]

  connect() {
    // set all to false on page refresh
    this.rowCheckboxTargets.map(x => x.checked = false)
    this.headerCheckboxTarget.checked = false
  }

  hideCheckboxes() {
    this.headerCheckboxTarget.style.visibility = "hidden";
    this.rowCheckboxTargets.map(x => x.style.visibility = "hidden")
    this._hideContextToolbar();
  }

  showCheckboxes() {
    this.headerCheckboxTarget.style.visibility = "visible";
    this.rowCheckboxTargets.map(x => x.style.visibility = "visible")
    this._showContextToolbar()
  }

  toggleChildren() {
    let parentChecked = this.headerCheckboxTarget.checked
    this.rowCheckboxTargets.map(x => x.checked = parentChecked)
    this.rowTargets.forEach(child => {
      child.classList.toggle(this.selectedClass, parentChecked)
    })
    this._showContextToolbar()
  }

  toggleParent() {
    this.headerCheckboxTarget.checked = this._allChildrenChecked()
    this._showContextToolbar()
  }

  _showContextToolbar() {
    let count = this._checkedCount()
    this.toolbarNoneTarget.classList.toggle("hidden", count > 0);
    // we are swapping also form elements and until we disable them,
    // they appear in params and override each other
    this._toggle_disabled(this.toolbarNoneTarget, count > 0)
    this.toolbarOneTarget.classList.toggle("hidden", count !== 1);
    this._toggle_disabled(this.toolbarOneTarget, count !== 1)
    this.toolbarSomeTarget.classList.toggle("hidden", count < 2);
    this._toggle_disabled(this.toolbarSomeTarget, count < 2)
  }

  _hideContextToolbar() {
    this.toolbarNoneTarget.classList.toggle("hidden", true);
    // we are swapping also form elements and until we disable them,
    // they appear in params and override each other
    this._toggle_disabled(this.toolbarNoneTarget, true)
    this.toolbarOneTarget.classList.toggle("hidden", true);
    this._toggle_disabled(this.toolbarOneTarget, true)
    this.toolbarSomeTarget.classList.toggle("hidden", true);
    this._toggle_disabled(this.toolbarSomeTarget, true)
  }

  _toggle_disabled(element, disabled) {
    const children = element.querySelectorAll('*');
    children.forEach(child => {
      child.disabled = disabled;
    });
  }

  _checkedCount() {
    return this.rowCheckboxTargets.filter(x => x.checked).length;
  }

  _allChildrenChecked() {
    return !this.rowCheckboxTargets.map(x => x.checked).includes(false)
  }
}